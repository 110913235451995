import * as React from "react"
import Layout from "../../Layout/layout"
import { Link } from "gatsby"
import _uniqueId from "lodash/uniqueId"
import { useEffect, useState } from "react"
import Axios from "axios"
import "../../../css/bootstrap-tagsinput.css"
import "../../../css/bootstrap-utilities.css"
import "../../../css/chat-dock.css"
import "./contact_detail.css"
import Tags from "./tags"
import Emails from "./emails"
import Phones from "./phones"
import CompanyName from "./companyName"
import History from "./HistoryNote/history"
import AddDetailRow from "./AddDetailRow"
import Addresses from "./addresses"
import RaveScore from "./raveScore"
import JobTitle from "./jobTitle"
import JobRole from "./jobRole"
import CustomFields from "./CustomFields/customFields"
import TitleName from "../Common/titleName"
import {
  CONTACT_TYPE_HUMAN,
  NUMBER_RATTING,
  SLIDER_RATTING,
  STAR_RATTING,
  CUSTOM_TEXTAREA,
  CUSTOM_NUMBER,
  CUSTOM_OPTIONS,
  updateContactType,
  CUSTOM_ADDRESS,
  CUSTOM_URL,
  CUSTOM_TEXTBOX,
  CUSTOM_EMAIL,
} from "../contactHelper"
import CustomFieldSlider from "./CustomFields/customFieldSlider"
import CustomFieldStar from "./CustomFields/customFieldStar"
import CustomFieldNumberRatting from "./CustomFields/customFieldNumberRatting"
import CustomFieldNumber from "./CustomFields/customFieldNumber"
import CustomFieldEmail from "./CustomFields/customFieldEmail.js"
import CustomFieldTextbox from "./CustomFields/customFieldTextbox"
import { CONTACT_META_DATE_FIELDS } from "../../../services/globalStaticData"
import MetaData from "./metaData"
import DateMetaData from "./dateMetaData"
import TaskPanel from "./panels/taskPanel"
import CalendarPanel from "./panels/calendarPanel"
import AccountingPanel from "./panels/accountingPanel"
import FilesPanel from "./panels/filesPanel"
import PrimaryCategory from "./primaryCategory"
import "./../../../css/slider_rating_custom_design.css"
import {
  EMAIL_ICON,
  EMAIL_TYPE,
  PRIVATE_NOTE_ICON,
  PRIVATE_NOTE_TYPE,
  emailLabel,
  privateNoteLabel,
  historyOptionLabel,
  HISTORY_ICON,
  HISTORY_TYPE,
  SMS_TYPE,
  smsLabel,
} from "./HistoryNote/historyNoteHelper"
import Seo from "../../seo"
import CustomFieldOptions from "./CustomFields/customFieldOptions"
import AxiosInstance from "../../../services/axiosInstance"
import SubContact from "./subContact"
import ContactFileUpload from "../FileUpload/contactFileUpload"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import AddSubContact from "./addSubContact"
import {
  isShowState,
  VIEW_STATE_HIDE,
  VIEW_STATE_OPEN,
  VIEW_STATE_SHOW,
} from "../../../services/globalHelpers"
import TransactionStatus from "./transactionStatus.js"
import AddTaskModalForContact from "./addTaskModelForContact.js"
import SMSType from "../../Drive/Doc/UserExperence/SMSType.js"

const Detail = ({ selectedContactId, selectedEmail }) => {
  const contactApi = "/contact",
    [contact, setContact] = useState(null),
    [subContactData, setSubContactData] = useState([]),
    [contactTaskData, setContactTaskData] = useState([]),
    [contactEventData, setContactEventData] = useState([]),
    [isPastEvent, setIsPastEvent] = useState(false),
    [contactAllEvent, setContactAllEvent] = useState([]),
    [loadContactDetails, setLoadContactDetails] = useState(true),
    [isViewTags, setIsViewTags] = useState(false)

  const getContactData = async selectedContactId => {
    await AxiosInstance.get(`${contactApi}/detail/${selectedContactId}`).then(response => {
      fillContactData(response.data.contact)
      setContactTaskData(response.data.contact.taskContacts)
    })
  }

  const getContactEvents = async selectedContactId => {
    try {
      const fromDate = ""
      const toDate = ""

      const response = await AxiosInstance.get(
        `/calendar?from_date=${fromDate}&to_date=${toDate}&contact_id=${selectedContactId}`
      )
      if (response.status === 200) {
        var calendarContacts = response.data.items
        setContactAllEvent(calendarContacts)

        const currentDate = new Date()

        var calendarContactsData = calendarContacts.filter(function (data) {
          if (isPastEvent) {
            return new Date(data.startTime) < currentDate
          } else {
            return new Date(data.startTime) > currentDate
          }
        })

        setContactEventData(calendarContactsData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [contactName, setContactName] = useState(""),
    [tempContactName, setTempContactName] = useState(""),
    [contactType, setContactType] = useState(""),
    [companyName, setCompanyName] = useState(null),
    [jobTitle, setJobTitle] = useState(null),
    [jobRole, setJobRole] = useState(null),
    [raveScore, setRaveScore] = useState(0),
    [role, setRole] = useState(""),
    [profilePic, setProfilePic] = useState(process.env.GATSBY_WRANGLER_URL + "StaticImages/avatar.gif"),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [emails, setEmails] = useState([]),
    [phones, setPhones] = useState([]),
    [addresses, setAddresses] = useState([]),
    [metaData, setMetaData] = useState([]),
    [customFields, setCustomFields] = useState([]),
    [historyNotes, setHistoryNotes] = useState([]),
    [dataRows, setDataRows] = useState([]),
    [primaryCategory, setPrimaryCategory] = useState(null),
    [tempPrimaryCategory, setTempPrimaryCategory] = useState(null),
    [showHistory, setShowHistory] = useState(true),
    [historyType, setHistoryType] = useState(PRIVATE_NOTE_TYPE),
    [historyLabel, setHistoryLabel] = useState(privateNoteLabel()),
    [historyValue, setHistoryValue] = useState(null),
    [historyIcon, setHistoryIcon] = useState(PRIVATE_NOTE_ICON),
    [modalShow, setModalShow] = useState(false),
    [linkModal, setLinkModal] = useState(false),
    [driveWorkRoom, setDriveWorkRoom] = useState([]),
    [driveAllData, setDriveAllData] = useState([]),
    [viewState, setViewState] = useState([]),
    [sequenceData, setSequenceData] = useState([]),
    [transactionValue, setTransactionValue] = useState(""),
    [showModal, setShowModal] = useState(false),
    [defaultMessage, setDefaultMessage] = useState("")

  useEffect(() => {
    if (selectedEmail) {
      setHistoryIcon(EMAIL_ICON)
      setHistoryLabel(emailLabel(selectedEmail))
      setHistoryValue(selectedEmail)
      setHistoryType("email")
    }
  }, [])

  useEffect(() => {
    if (driveWorkRoom.length > 0) {
      let driveData = []
      driveWorkRoom.map(drive => {
        driveData.push(drive.driveAll)
      })
      setDriveAllData(driveData)
    }
  }, [driveWorkRoom])

  const fillContactData = contactData => {
    setContact(contactData)
    setContactName(contactData.name)
    setTempContactName(contactData.name)
    setContactType(contactData.contactType)
    if (contactData.profilePic) {
      setProfilePic(process.env.GATSBY_WRANGLER_URL + contactData.profilePic)
    }
    setTags(contactData.tags)
    setTempTags(contactData.tags)
    setCompanyName(contactData.companyName)
    setJobTitle(contactData.jobTitle)
    setJobRole(contactData.jobRole)
    setRole(contactData.role)
    setRaveScore(parseFloat(contactData.raveScore))
    setEmails(contactData.emails)
    setPhones(contactData.phones)
    setAddresses(contactData.addresses)
    setMetaData(contactData.metaData)
    setCustomFields(contactData.contactCustomFields)
    setHistoryNotes(contactData.historyNotes)
    setPrimaryCategory(contactData.primaryCategory)
    setTempPrimaryCategory(contactData.primaryCategory)
    setSubContactData(contactData.subContacts)
    setDriveWorkRoom(contactData.driveWorkrooms ?? [])
    setViewState(contactData.metaDataJson?.view_state ?? [])
  }

  const [settingCustomFields, setSettingCustomFields] = useState([])
  const [loadSettingCustomFields, setLoadSettingCustomFields] = useState(true)
  const getSettingCustomFields = () => {
    return new Promise(async () => {
      await AxiosInstance.get("/settings/custom-field").then(function (response) {
        if (response.status === 200) {
          setSettingCustomFields(response.data)
        }
      })
    })
  }
  const [industryData, setIndustryData] = useState([])
  const [loadIndustryData, setLoadIndustryData] = useState(true)
  const getIndustryData = () => {
    return new Promise(async () => {
      await Axios.get(`${process.env.GATSBY_API_URL}/industry/list`).then(function (response) {
        if (response.status === 200) {
          setIndustryData(response.data.data)
        }
      })
    })
  }

  const [projectTypes, setProjectTypes] = useState([])
  const getProjectTypeData = () => {
    return new Promise(async () => {
      await AxiosInstance.get("/settings/transaction-type").then(function (response) {
        if (response.status === 200) {
          setProjectTypes(response.data.items)
        }
      })
    })
  }

  const addNewDataRow = e => {
    e.preventDefault()
    setDataRows([...dataRows, _uniqueId("contact_add_data_row_")])
  }

  const removeNewDataRow = removeIndex => {
    let tempDataRows = []

    dataRows.map((dataRow, dataRowIndex) => {
      if (removeIndex !== dataRowIndex) {
        tempDataRows = [...tempDataRows, dataRow]
      }
      if (dataRowIndex === dataRows.length - 1) {
        setDataRows([...tempDataRows])
      }
    })
  }

  const handleModalShow = () => {
    setModalShow(true)
  }
  const handleLinkModalShow = () => {
    setLinkModal(true)
  }

  const handleViewState = (type, value) => {
    AxiosInstance.post(`/contact/update-view-state/${selectedContactId}`, { type, state: value })
      .then(function (response) {
        setViewState({ ...viewState, [type]: value })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getSequenceList = () => {
    AxiosInstance.get(`/drive/sequence`).then(response => {
      setSequenceData(response.data.items)
    })
  }

  const handleHistoryChat = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const historyType = queryParams.get("historyType")
    const previewUrl = queryParams.get("previewUrl")

    if (historyType === EMAIL_TYPE) {
      setShowHistory(true)
      setHistoryType(EMAIL_TYPE)
      setHistoryLabel(emailLabel(emails[0]?.email))
      setHistoryValue(emails[0]?.email)
    }
    if (historyType === SMS_TYPE) {
      setShowHistory(true)
      setHistoryType(SMS_TYPE)
      setHistoryLabel(smsLabel(phones[0]?.phone))
      setHistoryValue(phones[0]?.phone)
    }
    setDefaultMessage(previewUrl)
  }

  useEffect(() => {
    if (loadContactDetails) {
      setLoadContactDetails(false)
      getContactData(selectedContactId)
    }

    if (loadSettingCustomFields) {
      setLoadSettingCustomFields(false)
      getSettingCustomFields()
    }

    if (loadIndustryData) {
      setLoadIndustryData(false)
      getIndustryData()
    }
  })

  useEffect(() => {
    getProjectTypeData()
    getSequenceList()
  }, [])

  useEffect(() => {
    getContactData(selectedContactId)
    getContactEvents(selectedContactId)
  }, [isPastEvent])

  useEffect(() => {
    handleHistoryChat()
  }, [emails, phones])

  return (
    <Layout>
      {contact && <Seo title={contactName} />}
      <div id="right-section" className="h-100">
        {contact && (
          <div className="row g-0" id={"contactDetailTemplate"}>
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-6 col-md-12 contactDetailLeftSection">
                  <div className="row g-0">
                    <div className="col-sm-9 mb-3">
                      <div className="pageTitle_Editor">
                        <Link
                          to={`${
                            contact.contactGroups.length > 0
                              ? "/contacts/groups/detail/" + contact.contactGroups[0].id
                              : "/contacts"
                          }`}
                          className="goBack"
                        >
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <button
                          className="CD_UserType"
                          data-bs-toggle="tooltip"
                          title={contactType === CONTACT_TYPE_HUMAN ? "Human" : "Company"}
                          onClick={() => {
                            updateContactType(contactType, setContactType, contact.id)
                          }}
                        >
                          <i
                            className={
                              contactType === CONTACT_TYPE_HUMAN ? "bi bi-person-fill" : "bi bi-building-fill"
                            }
                          />
                        </button>
                        <TitleName
                          entityId={contact.id}
                          api={contactApi}
                          name={contactName}
                          tempName={tempContactName}
                          setTempName={setTempContactName}
                          getContactData={getContactData}
                        />

                        <a
                          className="btnPageTitleSearch"
                          href={`https://www.google.com/search?q=${encodeURIComponent(contactName)}`}
                          target="_blank"
                        >
                          <i className="bi bi-search" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-lg-12 mb-3 contactDetailsContainer">
                      <div className="row g-0">
                        <div className="col p-3 position-relative">
                          <div
                            className="CD_Avatar"
                            style={{ background: `url("${profilePic}") no-repeat center / cover` }}
                          >
                            <img src={profilePic} className="invisible img-fluid" />

                            <ContactFileUpload contactId={contact.id} getContactData={getContactData} />
                          </div>
                          <div className="CD_Info_Row position-relative w-75">
                            <Tags
                              entityId={contact.id}
                              api={contactApi}
                              tags={tags}
                              setTags={setTags}
                              tempTags={tempTags}
                              setTempTags={setTempTags}
                              isViewTags={isViewTags}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col border-top">
                          {contactTaskData.length > 0 && (
                            <TransactionStatus contactTaskData={contactTaskData} />
                          )}

                          {subContactData.length > 0 && (
                            <SubContact
                              contactType={contactType}
                              contactId={contact.id}
                              subContacts={subContactData}
                              setSubContactData={setSubContactData}
                              selectedContactId={selectedContactId}
                              setContactType={setContactType}
                            />
                          )}

                          <div className="row">
                            {/*{contact && (
                                <RaveScore
                                  contactId={contact.id}
                                  raveScore={contact.raveScore}
                                  setRaveScore={setRaveScore}
                                />
                              )}*/}

                            {companyName !== null && companyName !== "" && (
                              <CompanyName
                                contactId={contact.id}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                              />
                            )}

                            {jobTitle !== null && jobTitle !== "" && (
                              <JobTitle
                                contactId={contact.id}
                                jobTitle={jobTitle}
                                setJobTitle={setJobTitle}
                              />
                            )}

                            {jobRole !== null && jobRole !== "" && (
                              <JobRole contactId={contact.id} jobRole={jobRole} setJobRole={setJobRole} />
                            )}
                            {/*{alert(tempPrimaryCategory)}*/}
                            {tempPrimaryCategory !== null && tempPrimaryCategory !== "" && (
                              <PrimaryCategory
                                contactId={contact.id}
                                primaryCategory={primaryCategory}
                                tempPrimaryCategory={tempPrimaryCategory}
                                setPrimaryCategory={setPrimaryCategory}
                                setTempPrimaryCategory={setTempPrimaryCategory}
                                industryData={industryData}
                              />
                            )}

                            {emails !== null &&
                              emails.length > 0 &&
                              emails.map(emailData => {
                                return (
                                  <Emails
                                    contactId={contact.id}
                                    emailData={emailData}
                                    setEmails={setEmails}
                                    getContactData={getContactData}
                                    setHistoryType={setHistoryType}
                                    setHistoryLabel={setHistoryLabel}
                                    setHistoryIcon={setHistoryIcon}
                                    setHistoryValue={setHistoryValue}
                                    key={emailData.id}
                                  />
                                )
                              })}

                            {phones !== null &&
                              phones.length > 0 &&
                              phones.map(phoneData => {
                                return (
                                  <Phones
                                    contactId={contact.id}
                                    phoneData={phoneData}
                                    setPhones={setPhones}
                                    getContactData={getContactData}
                                    setHistoryType={setHistoryType}
                                    setHistoryLabel={setHistoryLabel}
                                    setHistoryIcon={setHistoryIcon}
                                    setHistoryValue={setHistoryValue}
                                    key={phoneData.id}
                                  />
                                )
                              })}

                            {addresses !== null &&
                              addresses.length > 0 &&
                              addresses.map(addressData => {
                                return (
                                  <Addresses
                                    contactId={contact.id}
                                    addressData={addressData}
                                    setAddresses={setAddresses}
                                    getContactData={getContactData}
                                    setHistoryType={setHistoryType}
                                    setHistoryLabel={setHistoryLabel}
                                    setHistoryIcon={setHistoryIcon}
                                    setHistoryValue={setHistoryValue}
                                    key={addressData.id}
                                  />
                                )
                              })}

                            {metaData &&
                              metaData.length > 0 &&
                              metaData.map(data => {
                                return (
                                  <>
                                    {CONTACT_META_DATE_FIELDS.indexOf(data.type) === -1 && (
                                      <MetaData
                                        contactId={contact.id}
                                        data={data}
                                        setMetaData={setMetaData}
                                        getContactData={getContactData}
                                        industryData={industryData}
                                      />
                                    )}
                                    {CONTACT_META_DATE_FIELDS.indexOf(data.type) !== -1 && (
                                      <DateMetaData
                                        contactId={contact.id}
                                        data={data}
                                        setMetaData={setMetaData}
                                        getContactData={getContactData}
                                      />
                                    )}
                                  </>
                                )
                              })}

                            {customFields &&
                              customFields.length > 0 &&
                              customFields.map(customField => {
                                let customFieldComponent = ""
                                let customData = {
                                  contactId: contact.id,
                                  customField: customField,
                                  getContactData: getContactData,
                                  setCustomFields: setCustomFields,
                                  setHistoryType: setHistoryType,
                                  setHistoryLabel: setHistoryLabel,
                                  setHistoryIcon: setHistoryIcon,
                                  setHistoryValue: setHistoryValue,
                                }

                                switch (customField.type) {
                                  case STAR_RATTING:
                                    customFieldComponent = <CustomFieldStar {...customData} />
                                    break
                                  case SLIDER_RATTING:
                                    customFieldComponent = <CustomFieldSlider {...customData} />
                                    break
                                  case NUMBER_RATTING:
                                    customFieldComponent = <CustomFieldNumberRatting {...customData} />
                                    break
                                  case CUSTOM_TEXTBOX:
                                    customFieldComponent = <CustomFieldTextbox {...customData} />
                                    break
                                  case CUSTOM_EMAIL:
                                    customFieldComponent = <CustomFieldEmail {...customData} />
                                    break
                                  case CUSTOM_NUMBER:
                                    customFieldComponent = <CustomFieldNumber {...customData} />
                                    break
                                  case CUSTOM_OPTIONS:
                                    customFieldComponent = <CustomFieldOptions {...customData} />
                                    break
                                  case CUSTOM_TEXTAREA:
                                    customFieldComponent = <CustomFields {...customData} />
                                    break
                                  case CUSTOM_ADDRESS:
                                    customFieldComponent = <CustomFields {...customData} />
                                    break
                                  case CUSTOM_URL:
                                    customFieldComponent = <CustomFields {...customData} />
                                    break
                                }

                                return customFieldComponent
                              })}

                            {dataRows.length > 0 &&
                              dataRows.map((dataRow, dataRowIndex) => (
                                <AddDetailRow
                                  contact={contact}
                                  removeNewDataRow={removeNewDataRow}
                                  settingCustomFields={settingCustomFields}
                                  getContactData={getContactData}
                                  key={dataRow}
                                  dataRowIndex={dataRowIndex}
                                  primaryCategory={primaryCategory}
                                  industryData={industryData}
                                  companyName={companyName}
                                  jobTitle={jobTitle}
                                  jobRole={jobRole}
                                />
                              ))}

                            <div className="CD_Info_Row CD_Info_AddNew_Row  p-3">
                              <div className="row align-items-center">
                                <div className="col-sm-12 text-center">
                                  <div className="d-inline-block">
                                    <a
                                      href="#"
                                      className="btnAddNewGreenDashed"
                                      onClick={() => {
                                        setIsViewTags(true)
                                      }}
                                    >
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Tags</Tooltip>}>
                                        <i className="bi bi-tag-fill" />
                                      </OverlayTrigger>
                                    </a>
                                  </div>{" "}
                                  <div className="d-inline-block">
                                    <a
                                      href="#"
                                      className="btnAddNewGreenDashed btnAddNewContactDetail"
                                      onClick={addNewDataRow}
                                    >
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Add New</Tooltip>}>
                                        <i className="bi bi-plus-lg" />
                                      </OverlayTrigger>
                                    </a>
                                  </div>{" "}
                                  <div className="d-inline-block">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        variant="basic"
                                        className="btnAddNewGreenDashed btnAddNewContactAtCompanyLinked show no-dropdown-arrow"
                                        type="button"
                                      >
                                        <i className="bi bi-person-fill-add" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu data-popper-placement="bottom-start">
                                        <Dropdown.Item href="#" onClick={handleModalShow}>
                                          <i className="bi bi-person-fill-add" />{" "}
                                          {contactType === CONTACT_TYPE_HUMAN
                                            ? "Add Related Contact"
                                            : "Add Company Contact"}
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={handleLinkModalShow}>
                                          <i className="bi bi-link-45deg" />{" "}
                                          {contactType === CONTACT_TYPE_HUMAN
                                            ? "Link Related Contact"
                                            : "Link Contact to Company"}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    <AddSubContact
                                      contactType={contactType}
                                      contactId={contact.id}
                                      subContacts={subContactData}
                                      setSubContactData={setSubContactData}
                                      selectedContactId={selectedContactId}
                                      setContactType={setContactType}
                                      setModalShow={setModalShow}
                                      setLinkModal={setLinkModal}
                                      modalShow={modalShow}
                                      linkModal={linkModal}
                                    />
                                  </div>{" "}
                                  <div className="d-inline-block btnAddNewCustomerJourney">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        id="dropdown-basic-deal-type"
                                        variant="basic"
                                        className="btn btnAddNewGreenDashed dropdown-toggle no-dropdown-arrow"
                                        type="button"
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip className="ms-1 d-inline-block">
                                              Add Potential Sale Opportunity / Project / Job / Workflow
                                            </Tooltip>
                                          }
                                        >
                                          <i className="bi bi-check-square" />
                                        </OverlayTrigger>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu data-popper-placement="bottom-start">
                                        {projectTypes.map((projectType, index) => {
                                          return (
                                            <Dropdown.Item
                                              href="#"
                                              key={index}
                                              className="no-underline"
                                              onClick={() => {
                                                setShowModal(true)
                                                setTransactionValue(projectType.id)
                                              }}
                                            >
                                              <i className={`bi bi-${projectType.icon}`} />{" "}
                                              {projectType.dealTypeLabel}
                                            </Dropdown.Item>
                                          )
                                        })}
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    {showModal && (
                                      <AddTaskModalForContact
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        transactionValue={transactionValue}
                                        setTransactionValue={setTransactionValue}
                                        selectedContactId={selectedContactId}
                                        tempContactName={tempContactName}
                                        profilePic={profilePic}
                                        getContactData={getContactData}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*<TaskPanel />*/}

                    {isShowState(viewState.calendar) && (
                      <CalendarPanel
                        contactEventData={contactEventData}
                        isPastEvent={isPastEvent}
                        setIsPastEvent={setIsPastEvent}
                        selectedContactId={selectedContactId}
                        selectedContactName={tempContactName}
                        selectedContactImage={profilePic}
                      />
                    )}

                    {/*<AccountingPanel />*/}

                    {isShowState(viewState.file) && driveAllData[0]?.id && (
                      <FilesPanel
                        driveWorkRoom={driveAllData}
                        handleViewState={handleViewState}
                        viewState={viewState}
                      />
                    )}

                    <div className="col-lg-12 mb-3 text-center footer-action">
                      {/*<button
                        className={`btnAddNewGreenDashed btnContactDetailTasksProjects${
                          isShowState(viewState.task) ? " active" : ""
                        }`}
                        onClick={() => {
                          handleViewState(
                            "task",
                            typeof viewState.task === "undefined" || viewState.task === VIEW_STATE_HIDE
                              ? VIEW_STATE_SHOW
                              : VIEW_STATE_HIDE
                          )
                        }}
                      >
                        <OverlayTrigger overlay={<Tooltip>Tasks &amp; Projects</Tooltip>}>
                          <i className="bi bi-check2-square" />
                        </OverlayTrigger>
                      </button>{" "}
                      <button className="btnAddNewGreenDashed btnContactDetailCalendar">
                        <OverlayTrigger overlay={<Tooltip>Calendar</Tooltip>}>
                          <i className="bi bi-calendar-check" />
                        </OverlayTrigger>
                      </button>{" "}
                      <button className="btnAddNewGreenDashed btnContactDetailAccounting">
                        <OverlayTrigger overlay={<Tooltip>Accounting</Tooltip>}>
                          <i className="bi bi-card-list" />
                        </OverlayTrigger>
                      </button>{" "}*/}
                      <button
                        className={`btnAddNewGreenDashed btnContactDetailCalendar${
                          isShowState(viewState.calendar) ? " active" : ""
                        }`}
                        onClick={() => {
                          handleViewState(
                            "calendar",
                            typeof viewState.calendar === "undefined" ||
                              viewState.calendar === VIEW_STATE_HIDE
                              ? VIEW_STATE_OPEN
                              : VIEW_STATE_HIDE
                          )
                        }}
                      >
                        <OverlayTrigger overlay={<Tooltip>Calendar</Tooltip>}>
                          <i className="bi bi-calendar-check" />
                        </OverlayTrigger>
                      </button>{" "}
                      {driveAllData[0]?.id && (
                        <>
                          <button
                            className={`btnAddNewGreenDashed btnContactDetailFiles${
                              isShowState(viewState.file) ? " active" : ""
                            }`}
                            onClick={() => {
                              handleViewState(
                                "file",
                                typeof viewState.file === "undefined" || viewState.file === VIEW_STATE_HIDE
                                  ? VIEW_STATE_OPEN
                                  : VIEW_STATE_HIDE
                              )
                            }}
                          >
                            <OverlayTrigger overlay={<Tooltip>Files</Tooltip>}>
                              <i className="bi bi-paperclip" />
                            </OverlayTrigger>
                          </button>{" "}
                        </>
                      )}
                    </div>

                    {!showHistory && (
                      <div className="col-lg-12 text-center">
                        <button
                          type="button"
                          className="btn btn-default btn-lg btnOpenChatDock"
                          data-bs-toggle="tooltip"
                          title="History"
                          onClick={() => setShowHistory(true)}
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </button>
                      </div>
                    )}

                    {/*<div className="col-lg-12 mb-3" id="task_panel">
                      <div className="accordion-item panel panel-grey">
                        <div
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#subtask_container"
                          aria-expanded="false"
                          aria-controls="subtask_container"
                        >
                          <i className="bi bi-check2-square me-2" /> Tasks & Projects
                        </div>
                        <div
                          id="subtask_container"
                          className="accordion-collapse collapse"
                          aria-labelledby="subtask_container"
                        >
                          <div className="accordion-body">
                            <h3 className="text-center">Coming soon</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="timeclock_panel">
                      <div className="accordion-item panel panel-grey">
                        <div
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#task_time_clock_container"
                          aria-expanded="false"
                          aria-controls="task_time_clock_container"
                        >
                          <i className="bi bi-clock me-2" /> Timeclock
                        </div>
                        <div
                          id="task_time_clock_container"
                          className="accordion-collapse collapse"
                          aria-labelledby="task_time_clock_container"
                        >
                          <div className="accordion-body">
                            <h3 className="text-center">Coming soon</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="calendar_panel">
                      <div className="accordion-item panel panel-pink">
                        <div
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#calendar_container"
                          aria-expanded="false"
                          aria-controls="calendar_container"
                        >
                          <i className="bi bi-calendar me-2" /> Calendar
                        </div>
                        <div
                          id="calendar_container"
                          className="accordion-collapse collapse"
                          aria-labelledby="calendar_container"
                        >
                          <div className="accordion-body">
                            <div className="row g-0">
                              <div className="col tableContainer">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="float-end">
                                      <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                                        <li className="nav-item">
                                          <button type="button" className="btn btn-primary mt-1 me-1">
                                            <i className="bi bi-plus-lg" /> Add New
                                          </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                          <a
                                            className="nav-link active"
                                            id="upcoming-event-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#upcoming-event-tab-pane"
                                            role="tab"
                                            aria-controls="upcoming-event-tab-pane"
                                            aria-selected="false"
                                          >
                                            <span data-bs-toggle="tooltip" title="Upcoming Events">
                                              <i className="bi bi-clock-history fa-flip-horizontal"></i>
                                            </span>
                                          </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                          <a
                                            className="nav-link"
                                            id="past-event-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#past-event-tab-pane"
                                            role="tab"
                                            aria-controls="past-event-tab-pane"
                                            aria-selected="false"
                                          >
                                            <span data-bs-toggle="tooltip" title="Past Events">
                                              <i className="bi bi-clock-history"></i>
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 tableFadeOut">
                                    <div className="tab-content autoAdjustSearchBox" id="CalendarTabs">
                                      <div
                                        className="tab-pane fade show active"
                                        id="upcoming-event-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="upcoming-event-tab"
                                        tabIndex="0"
                                      >
                                        <table
                                          id="tblUpcomingEvents"
                                          className="table dataTable"
                                          cellSpacing="0"
                                          width="100%"
                                        >
                                          <thead>
                                            <tr>
                                              <th width="70%" className="nowrap">
                                                Event
                                              </th>
                                              <th width="30%" className="d-none d-md-table-cell">
                                                Scheduled
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Event 1</td>
                                              <td className="d-none d-md-table-cell">
                                                <TimeAgo datetime="2022-12-25" />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Event 2</td>
                                              <td className="d-none d-md-table-cell">
                                                <TimeAgo datetime="2022-12-31" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div
                                        className="tab-pane fade"
                                        id="past-event-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="past-event-tab"
                                        tabIndex="0"
                                      >
                                        <table
                                          id="tblPastEvents"
                                          className="table dataTable"
                                          cellSpacing="0"
                                          width="100%"
                                        >
                                          <thead>
                                            <tr>
                                              <th width="70%" className="nowrap">
                                                Event
                                              </th>
                                              <th width="30%" className="d-none d-md-table-cell">
                                                Scheduled
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Event 1</td>
                                              <td className="d-none d-md-table-cell">
                                                <TimeAgo datetime="2022-10-24" />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="accounting_panel">
                      <div className="accordion-item panel panel-blue">
                        <div
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#account_container"
                          aria-expanded="false"
                          aria-controls="account_container"
                        >
                          <i className="bi bi-card-list me-2" /> Accounting
                        </div>
                        <div
                          id="account_container"
                          className="accordion-collapse collapse"
                          aria-labelledby="account_container"
                        >
                          <div className="accordion-body">
                            <h3 className="text-center">Coming soon</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="files_panel">
                      <div className="accordion-item panel panel-beige">
                        <div
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#files_container"
                          aria-expanded="false"
                          aria-controls="files_container"
                        >
                          <i className="bi bi-paperclip me-2" /> Files
                        </div>
                        <div
                          id="files_container"
                          className="accordion-collapse collapse"
                          aria-labelledby="files_container"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="float-end">
                                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                                    <li className="nav-item">
                                      <button type="button" className="btn btn-secondary btnUpload">
                                        <i className="fa-solid fa-cloud-arrow-up" />
                                        <span className="d-none d-md-inline">Upload</span>
                                      </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <a
                                        className="nav-link active"
                                        id="drive-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#drive"
                                        role="tab"
                                        aria-controls="drive"
                                        aria-selected="false"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Drive"
                                      >
                                        <i className="bi bi-bar-chart" />
                                      </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <a
                                        className="nav-link"
                                        id="small-icon-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#small-icon"
                                        role="tab"
                                        aria-controls="small-icon"
                                        aria-selected="false"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Small Icons"
                                      >
                                        <i className="fa-solid fa-table-cells-large" />
                                      </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                      <a
                                        className="nav-link"
                                        id="large-icon-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#large-icon"
                                        role="tab"
                                        aria-controls="large-icon"
                                        aria-selected="false"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Large Icons"
                                      >
                                        <i className="bi bi-stop-fill" />
                                      </a>
                                    </li>
                                    <li className="nav-item nav-item-trash" role="presentation">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View Templates"
                                      >
                                        <i className="fa-regular fa-clone" />
                                      </a>
                                    </li>
                                    <li className="nav-item nav-item-trash" role="presentation">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Trash"
                                      >
                                        <i className="bi bi-trash-fill" />
                                      </a>
                                    </li>
                                  </ul>

                                  <ul
                                    className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <button type="button" className="btn btn-secondary btnUpload me-1">
                                        <i className="fa-solid fa-cloud-arrow-up" />
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <div className="btn-group btnDriveView" role="group">
                                        <button
                                          id="btnGroupDrop3"
                                          type="button"
                                          className="btn btn-default dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="bi bi-bar-chart" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              id="drive-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#drive"
                                              role="tab"
                                              aria-controls="drive"
                                              aria-selected="false"
                                            >
                                              <i className="bi bi-bar-chart" /> Drive
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              id="small-icon-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#small-icon"
                                              role="tab"
                                              aria-controls="small-icon"
                                              aria-selected="false"
                                            >
                                              <i className="fa-solid fa-table-cells-large" /> Small Icons
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              id="large-icon-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#large-icon"
                                              role="tab"
                                              aria-controls="large-icon"
                                              aria-selected="false"
                                            >
                                              <i className="bi bi-stop-fill" /> Large Icons
                                            </a>
                                          </li>
                                          <li>
                                            <a className="dropdown-item" href="#">
                                              <i className="fa-regular fa-clone" /> Templates
                                            </a>
                                          </li>
                                          <li>
                                            <a className="dropdown-item" href="#">
                                              <i className="bi bi-trash-fill" /> Trash
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="tab-content autoAdjustSearchBox" id="myTabContent">
                                  <div
                                    className="tab-pane fade show active"
                                    id="drive"
                                    role="tabpanel"
                                    aria-labelledby="drive-tab"
                                  >
                                    <table
                                      id="tblDrive"
                                      className="table dataTable"
                                      cellSpacing="0"
                                      width="100%"
                                    >
                                      <thead>
                                        <tr>
                                          <th width="5%">&nbsp;</th>
                                          <th width="70%">
                                            Name/Label
                                            <button
                                              type="button"
                                              className="btn btn-sm btnTHSoryByTags float-end me-3"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ContactsSortByTagModal"
                                              title="Advanced Tag Search"
                                            >
                                              <span data-toggle="tooltip" title="Advanced Tag Search">
                                                <i className="bi bi-tag-fill" />
                                              </span>
                                            </button>
                                          </th>
                                          <th width="18%" className="d-none d-md-table-cell">
                                            Last Activity
                                          </th>
                                          <th width="7%" className="text-end d-none d-lg-table-cell"></th>
                                        </tr>
                                        <tr className="d-none table_action_container">
                                          <td colSpan="4" className="text-left">
                                            <div className="batch-action-container">
                                              <button className="btn btn-trash my-1">
                                                <i className="bi bi-trash-fill" /> Move to Trash
                                              </button>
                                              <button
                                                className="btn btn-default my-1"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Archive (Searchable but Hidden)"
                                              >
                                                <i className="bi bi-archive-fill" /> Archive
                                              </button>
                                              <button className="btn btn-secondary my-1">
                                                <i className="bi bi-tag-fills" /> Apply Tag(s)
                                              </button>
                                              <button className="btn btn-success my-1">
                                                <i className="bi bi-person-fill-plus" /> Manage Sharing
                                              </button>
                                              <button className="btn btn-primary my-1">
                                                <i className="bi bi-unlock-fill" /> Manage Permissions
                                              </button>
                                              <button className="btn btn-secondary my-1">
                                                <i className="bi bi-arrows-move" /> Move To...
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="folderRow">
                                          <td className="tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID1"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID1" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-folder">
                                                <i className="fa-solid fa-folder" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Incoming</a>
                                            <div className="SwiftCloudTableTags">
                                              <ul>
                                                <li>
                                                  <a href="#" data-tagname="Tag1">
                                                    Tag1
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-01" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr className="folderRow">
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID2"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID2" className="css-checkbox-label" />
                                              <span className="tableColChkSpan btn-folder">
                                                <i className="fa-solid fa-folder" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Tasks and Projects</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-01" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID14"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID14" className="css-checkbox-label" />
                                              <span className="tableColChkSpan archiveIconBg">
                                                <i className="fa-regular fa-file-zipper" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="file_zip.html">Abc.zip</a>
                                            <div className="SwiftCloudTableTags">
                                              <ul>
                                                <li>
                                                  <a href="#" data-tagname="Zip">
                                                    Zip
                                                  </a>
                                                </li>
                                                <li>
                                                  <a href="#" data-tagname="Tag1">
                                                    Tag1
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="SwiftCloudSharedWithUsers">
                                              <ul>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="SwiftCloud"
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/CloudFlag_SolidBG_Color2760a8_margin.gif`}
                                                      alt="CloudFlag_SolidBG_Color2760a8_margin.gif"
                                                    />
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Rover V."
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`}
                                                      alt="roger.jpg"
                                                    />
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Amitabh Bachchan"
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                                                      alt="bachchan-amitabh-image.jpg"
                                                    />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-edit"
                                              data-toggle="tooltip"
                                              title="Download"
                                            >
                                              <i className="fa-solid fa-cloud-arrow-down" />
                                            </a>
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID15"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID15" className="css-checkbox-label" />
                                              <span className="tableColChkSpan videoIconBg">
                                                <i className="fa-regular fa-file-video" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="file_video.html">Video.mp4</a>
                                            <div className="SwiftCloudTableTags">
                                              <ul>
                                                <li>
                                                  <a href="#" data-tagname="Video">
                                                    Video
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="SwiftCloudSharedWithUsers">
                                              <ul>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Stephanie Harris"
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/susan.jpg`}
                                                    />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-edit"
                                              data-toggle="tooltip"
                                              title="Download"
                                            >
                                              <i className="fa-solid fa-cloud-arrow-down" />
                                            </a>
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID16"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID16" className="css-checkbox-label" />
                                              <span className="tableColChkSpan audioIconBg">
                                                <i className="fa-regular fa-file-audio" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="file_audio.html">Audio.mp3</a>
                                            <div className="SwiftCloudTableTags">
                                              <ul>
                                                <li>
                                                  <a href="#" data-tagname="Audio">
                                                    Audio
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="SwiftCloudSharedWithUsers">
                                              <ul>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Rover V."
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`}
                                                      alt="roger.jpg"
                                                    />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-edit"
                                              data-toggle="tooltip"
                                              title="Download"
                                            >
                                              <i className="fa-solid fa-cloud-arrow-down" />
                                            </a>
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID17"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID17" className="css-checkbox-label" />
                                              <span className="tableColChkSpan pdfIconBg">
                                                <i className="fa-regular fa-file-pdf" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="file_pdf.html">XYZ.pdf</a>
                                            <div className="SwiftCloudSharedWithUsers">
                                              <ul>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="SwiftCloud"
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                                                      alt="bachchan-amitabh-image.jpg"
                                                    />
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Rover V."
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/roger.jpg`}
                                                      alt="roger.jpg"
                                                    />
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="SOCIALIZERPOPUP_SHOWCURRENTSTATUS"
                                                    data-toggle="tooltip"
                                                    title="Amitabh Bachchan"
                                                  >
                                                    <img
                                                      src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/bachchan-amitabh-image.jpg`}
                                                      alt="bachchan-amitabh-image.jpg"
                                                    />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-edit"
                                              data-toggle="tooltip"
                                              title="Download"
                                            >
                                              <i className="fa-solid fa-cloud-arrow-down" />
                                            </a>
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID18"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID18" className="css-checkbox-label" />
                                              <span className="tableColChkSpan">
                                                <img
                                                  src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/pedro.jpg`}
                                                  alt="pedro.jpg"
                                                />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="file_image.html">Image.jpg</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-edit"
                                              data-toggle="tooltip"
                                              title="Download"
                                            >
                                              <i className="fa-solid fa-cloud-arrow-down" />
                                            </a>
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID3"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID3" className="css-checkbox-label" />
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-file-earmark-medical-fill" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">eDoc</a>
                                            <div className="SwiftCloudTableTags">
                                              <ul>
                                                <li>
                                                  <a href="#" data-tagname="Tag1">
                                                    Tag1
                                                  </a>
                                                </li>
                                                <li>
                                                  <a href="#" data-tagname="Tag2">
                                                    Tag2
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID4"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID4" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-table" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Spreadsheet</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus" />
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID5"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID5" className="css-checkbox-label" />
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-table" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Invoice</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID6"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID6" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-list-ul"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Web form</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID7"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID7" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-envelope-fill"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Envelope</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID19"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID19" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-person-fills"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Workroom</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID8"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID8" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-cart4"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Item you sell</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID9"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID9" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-three-dots"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Sequence</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID10"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID10" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-calendar3"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Scheduler</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID11"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID11" className="css-checkbox-label" />
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="fa fa-clone" />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Template</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID12"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID12" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-telephone-fill"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Phone number</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-center tableColChkDel">
                                            <div className="tableColChkDelBox">
                                              <input
                                                type="checkbox"
                                                name="fileid[]"
                                                id="C_ID13"
                                                className="css-checkbox"
                                              />
                                              <label htmlFor="C_ID13" className="css-checkbox-label"></label>
                                              <span className="tableColChkSpan btn-secondary">
                                                <i className="bi bi-box-arrow-in-right"></i>
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="#">Paywall</a>
                                            <a
                                              href="LINK_TO_SOCIALIZER_POPUP"
                                              className="btnSocializer"
                                              data-toggle="tooltip"
                                              title="Share / Attach to Contact"
                                            >
                                              <i className="bi bi-person-fill-plus"></i>
                                            </a>
                                          </td>
                                          <td className="d-none d-md-table-cell">
                                            <TimeAgo datetime="2022-05-15" />
                                          </td>
                                          <td className="text-end tableColAction d-none d-lg-table-cell">
                                            <a
                                              href="#"
                                              className="btn btn-delete"
                                              data-toggle="tooltip"
                                              title="Delete"
                                            >
                                              <i className="bi bi-trash-fill"></i>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="small-icon"
                                    role="tabpanel"
                                    aria-labelledby="small-icon-tab"
                                  >
                                    <ul className="smallIconList">
                                      <li className="folderIcon">
                                        <a href="#">
                                          <span>
                                            <i className="fa-solid fa-folder" />
                                          </span>
                                          <span className="fileName">Incoming</span>
                                        </a>
                                      </li>
                                      <li className="folderIcon">
                                        <a href="#">
                                          <span>
                                            <i className="fa-solid fa-folder" />
                                          </span>
                                          <span className="fileName">Tasks and Projects</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-file-earmark-medical-fill" />
                                          </span>
                                          <span className="fileName">eDoc</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-table" />
                                          </span>
                                          <span className="fileName">Spreadsheet</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-table" />
                                          </span>
                                          <span className="fileName">Invoice</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-list-ul" />
                                          </span>
                                          <span className="fileName">Web form</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-envelope-fill" />
                                          </span>
                                          <span className="fileName">Envelope</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-person-fills" />
                                          </span>
                                          <span className="fileName">Workroom</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-cart4" />
                                          </span>
                                          <span className="fileName">Item you sell</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-three-dots" />
                                          </span>
                                          <span className="fileName">Sequence</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-calendar3" />
                                          </span>
                                          <span className="fileName">Scheduler</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="fa fa-clone" />
                                          </span>
                                          <span className="fileName">Template</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-telephone-fill" />
                                          </span>
                                          <span className="fileName">Phone number</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="btn-secondary fileTypeIcon">
                                            <i className="bi bi-box-arrow-in-right" />
                                          </span>
                                          <span className="fileName">Paywall</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <span className="fileTypeIcon contact-detail-doc-with-featured-image" />
                                          <span className="fileName">Doc with featured image</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="large-icon"
                                    role="tabpanel"
                                    aria-labelledby="large-icon-tab"
                                  >
                                    <ul className="largeIconList">
                                      <li className="folderIcon">
                                        <a href="#">
                                          <span>
                                            <i className="fa-solid fa-folder" />
                                          </span>
                                          <span className="fileName">Incoming</span>
                                        </a>
                                      </li>
                                      <li className="folderIcon">
                                        <a href="#">
                                          <span>
                                            <i className="fa-solid fa-folder" />
                                          </span>
                                          <span className="fileName">Tasks and Projects</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-file-earmark-medical-fill" />
                                          </span>
                                          <span className="fileName">eDoc</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-table" />
                                          </span>
                                          <span className="fileName">Spreadsheet</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-table" />
                                          </span>
                                          <span className="fileName">Invoice</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-list-ul" />
                                          </span>
                                          <span className="fileName">Web form</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-envelope-fill" />
                                          </span>
                                          <span className="fileName">Envelope</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-person-fills" />
                                          </span>
                                          <span className="fileName">Workroom</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-cart4" />
                                          </span>
                                          <span className="fileName">Item you sell</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-three-dots" />
                                          </span>
                                          <span className="fileName">Sequence</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-calendar3" />
                                          </span>
                                          <span className="fileName">Scheduler</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="fa fa-clone" />
                                          </span>
                                          <span className="fileName">Template</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-telephone-fill" />
                                          </span>
                                          <span className="fileName">Phone number</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary">
                                        <a href="#">
                                          <span className="fileTypeIcon">
                                            <i className="bi bi-box-arrow-in-right" />
                                          </span>
                                          <span className="fileName">Paywall</span>
                                        </a>
                                      </li>
                                      <li className="btn-secondary contact-detail-doc-with-featured-image">
                                        <a href="#">
                                          <span className="fileName">Doc with featured image</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                </div>

                {showHistory && (
                  <History
                    contact={contact}
                    contactName={contactName}
                    profilePic={profilePic}
                    emails={emails}
                    phones={phones}
                    addresses={addresses}
                    customFields={customFields}
                    historyNotes={historyNotes}
                    setHistoryNotes={setHistoryNotes}
                    setShowHistory={setShowHistory}
                    historyType={historyType}
                    setHistoryType={setHistoryType}
                    historyLabel={historyLabel}
                    setHistoryLabel={setHistoryLabel}
                    historyIcon={historyIcon}
                    setHistoryIcon={setHistoryIcon}
                    historyValue={historyValue}
                    setHistoryValue={setHistoryValue}
                    sequenceData={sequenceData}
                    contactAllEvent={contactAllEvent}
                    selectedContactId={selectedContactId}
                    defaultMessage={defaultMessage}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Detail
